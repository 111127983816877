.upoad-pic{
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffc548;
	padding: 20px;
	min-height: 500px;
}
.upoad-pic .form-holder{
	max-width: 400px;
	margin-inline: auto;
	text-align: center;
}
.upoad-pic .form-holder .label{
	display: block;
	margin: 0 0 12px;
	text-align: center;
	font: 700 22px/1.2 var(--font-roboto);
}
.upoad-pic .form-holder .input-holder{
	display: flex;
	text-align: center;
	margin: 0 0 15px;
	justify-content: center;
}
.upoad-pic .form-holder .form-control{
	text-align: center;
	width: 50px;
}
.upoad-pic .form-holder .hyphen{
	margin: 0 5px;
	display: inline-block;
	vertical-align: middle;
	padding: 5px 0;
	font-size: 24px;
	line-height: 1;
	color: #665b5b;
}
.upoad-pic .form-holder .btn{
	display: inline-block;
	background: #000;
	color: #fff;
	border-radius: 5px;
	padding: 10px 20px;
	transition: all ease 0.5s;
}
.upoad-pic .form-holder .btn.disabled{
	visibility: hidden;
	opacity: 0;
}
.upoad-pic .form-holder .btn:hover{
	background: #14274b;
}
/** Page 404 **/
.page-not-found{
	text-align: center;
}
.page-not-found h1,
.page-not-found h2,
.page-not-found p{
	font: 700 80px/1 var(--font-roboto);
	margin: 0 0 10px;
}
.page-not-found h2{
	font-size: 20px;
	margin-bottom: 20px;
}
.page-not-found p{
	font-size: 16px;
	font-weight: normal;
}
/** Modal **/
.modal .modal-body{
	padding-top: 40px;
}
.modal .modal-content{
	text-align: center;
}
.modal .img{
	margin-bottom: 20px;
}
.modal .upload-file-area{
	border: 2px dashed #999;
	display: block;
	padding: 40px 30px 20px;
	border-radius: 10px;
	background: #eee;
	cursor: pointer;
	margin-bottom: 20px;
}
.modal .upload-file-area .icon{
	display: block;
	margin: 0 0 15px;
}
.modal .upload-file-area .icon img{
	filter: brightness(0) saturate(100%) invert(67%) sepia(3%) saturate(20%) hue-rotate(319deg) brightness(90%) contrast(90%);
}
.modal .upload-file-area h3{
	margin: 0 0 10px;
	color: #000;
	font: 700 24px/1.2 var(--font-roboto);
}
.modal .upload-file-area p{
	font: 18px/1.2 var(--font-roboto);
	color: #4c4c4c;
}
.modal .upload-file-area .file-hidden{
	opacity: 0;
	visibility: hidden;
}
.modal .uploaded-area .upload{
	display: flex;
	align-items: center;
	padding: 10px;
	border: 1px solid #dedede;
	background: #f6f6f6;
	position: relative;
	margin-bottom: 10px;
}
.modal .uploaded-area .img{
	max-width: 50px;
	max-height: 50px;
	overflow: hidden;
	border-radius: 10px;
	margin: 0 15px 0 0;
}
.modal .uploaded-area img{
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
}
.modal .uploaded-area .name{
	color: #000;
	font: 16px/1.2 var(--font-roboto);
}
.modal .uploaded-area .name p{
	margin: 0;
}
.modal .uploaded-area .close{
	border: 0;
	background: #d02424;
	color: #fff;
	border-radius: 100%;
	padding: 0 8px;
	position: absolute;
	right: 0;
	top: -12px;
	cursor: pointer;
	opacity: 1;
}
.modal .btn-submit{
	display: block;
	border: 0;
	background: #2197c3;
	color: #fff;
	width: 100%;
	padding: 10px 15px;
	border-radius: 5px;
	margin-bottom: 15px;
	transition: all ease 0.5s;
}
.modal .btn-submit:hover{
	background: #14274b;
}
.modal .btn-submit.disabled{
	opacity: 0;
	visibility: hidden;
}
.modal .btn-submit,
.modal .upload-file-area,
.modal .uploaded-area{
	max-width: 500px;
	margin-inline: auto;
}