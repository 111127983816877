.loading{
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 9999;
}
.loading .loader{
	background: #000;
	border-radius: 50%;
	height: 50px;
	left: 50%;
	margin-left: -23px;
	margin-top: -14px;
	position: absolute;
	top: 50%;
	width: 50px;
	animation: load 0.75s linear infinite;
}
.loading .loader:after{
	content: '';
	position: absolute;
	width: 49px;
	height: 49px;
	top: 1px;
	right: 0;
	left: 0;
	margin: auto;
	background: white;
	border-radius: 50%;
}
@keyframes load {
	to {
		transform: rotate(360deg);
	}
}