.blog-area{
	padding-bottom: 50px;
}
.blog-area .blog-heading{
	background: #feb825;
	min-height: 250px;
	display: flex;
	align-items: center;
	margin-bottom: 70px;
}
.blog-area .blog-heading .page-title{
	font-size: 36px;
	line-height: 1.2;
	font-weight: bold;
	margin: 0;
	display: block;
	text-align: center;
}
.blog-area .widget{
	border: 1px solid rgba(20, 39, 75, 0.5);
    padding: 25px;
    margin: 0 0 30px;
	border-radius: 5px;
}
@media only screen and (min-width: 992px){
	.blog-area .widget.sticky{
		position: sticky;
		top: 80px;
	}
}
.blog-area .widget .title {
	display: block;
    font-size: 22px;
    font-weight: 700;
    line-height: 1em;
    padding: 0 0 10px 0;
    border-bottom: 1px solid #ada6a6;
}
.blog-area .widget ul{
	margin: 0;
	padding: 0;
	list-style: none;
}
.blog-area .widget ul li:first-child {
    border-top: 0;
}
.blog-area .widget ul li {
	border-radius: 0;
	display: flex;
	align-items: center;
    border-top: 1px solid #ada6a6;
	transition: all ease 0.5s;
	flex-grow: 1;
}
.blog-area .widget ul li span{
	display: inline-block;
	vertical-align: middle;
	margin-bottom: 4px;
	margin-right: 5px;
}
.blog-area .widget ul a {
    text-decoration: none;
    display: block;
    padding: 9px 12px;
    color: #333;
    font-size: 16px;
    transition: all ease 0.5s;
	font-weight: 500;
	display: block;
	width: 100%;
}
.blog-area .widget ul li.active,
.blog-area .widget ul li a:hover{
	background: #14274b;
	cursor: pointer;
	color: #fff;
	border-top-color: #14274b;
}
.blog-area .widget ul li.active a{
	color: #fff;
}
.blog-area .post{
	margin-bottom: 25px;
	border-radius: 10px;
	border: 1px solid rgba(20, 39, 75, 0.5);
	overflow: hidden;
}
.blog-area .post .img{
	overflow: hidden;
}
.blog-area .post .img img{
	display: block;
	max-width: 100%;
	height: auto;
	transition: all ease 0.5s;
}
.blog-area .post.thumbnail .img{
	max-height: 400px;
}
.blog-area .post.thumbnail img{
	width: 100%;
	object-fit: cover;
	aspect-ratio: 3/1;
}
.blog-area .post .img a:hover img{
	transform: scale(1.1);
}
.blog-area .post .text{
	padding: 20px;
}
.blog-area .post h2{
	font-size: 18px;
	line-height: 1.2;
	margin: 0 0 15px;
	font-weight: 500;
	text-align: left;
}
.blog-area .post.detail{
	font-size: 14px;
	line-height: 20px;
}
.blog-area .post.detail h1{
font-size: 26px;
text-align: left;
}
.blog-area .post.detail h2{
	font-size: 24px;
	margin-bottom: 10px;
}
.blog-area .post h2 a{
	text-decoration: none;
	color: #212529;
	font-weight: bold;
}
.blog-area .post.detail h2,
.blog-area .post.detail h3,
.blog-area .post.detail h4{
	font-size: 22px;
	line-height: 1.2;
}
.blog-area .post p{
	margin: 0 0 15px;
}
.blog-area .post p a{
	text-decoration: none;
	color: #0d6efd;
}
.blog-area .post p a:hover{
	text-decoration: underline;
}
.blog-area .post h1 a:hover{
	text-decoration: underline;
}
.blog-area .post .btn{
	padding: 10px 25px;
	background: #14274b;
	transition: all ease 0.5s;
}
.blog-area .post .btn:hover{
	opacity: 0.7;
	color: #fff;
}
.blog-area .pagination{
	margin-top: 40px;
}
.blog-area .pagination .page-link{
	border-color: #14274b;
	padding: 8px 18px;
	color: #14274b;
}
.blog-area .pagination .page-item.active .page-link,
.blog-area .pagination .page-link:hover{
	background: #14274b;
	color: #fff;
}
@media only screen and (max-width: 767.98px){
	.blog-area .blog-heading{
		margin-bottom: 30px;
	}
	.blog-area .post.thumbnail{
		margin-bottom: 10px;
	}
	.blog-area .post.detail h1 {
		font-size: 20px;
		margin-bottom: 10px;
	}
}